var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fire-index-component" }, [
    _c("div", { staticClass: "page-header" }, [
      _c(
        "div",
        { staticClass: "page-breadcrumb" },
        [
          _c(
            "a-breadcrumb",
            [
              _c(
                "a-breadcrumb-item",
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/dashboard/parking-index" } },
                    [_vm._v("电梯管理")]
                  )
                ],
                1
              ),
              _c("a-breadcrumb-item", [_vm._v("概览")])
            ],
            1
          )
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "page-body page-body-margin" },
      [
        _c(
          "div",
          { staticClass: "parking-card-wrapper" },
          [
            _c(
              "a-row",
              {
                staticClass: "sub-system-header-card",
                attrs: {
                  type: "flex",
                  justify: "space-around",
                  align: "middle"
                }
              },
              _vm._l(_vm.headerCardList, function(item, index) {
                return _c(
                  "a-col",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: item.loading,
                        expression: "item.loading"
                      }
                    ],
                    key: index,
                    staticClass: "parking-card-item text-center"
                  },
                  [
                    _c("img", {
                      staticClass: "parking-card-item-icon",
                      attrs: { src: item.icon }
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "parking-card-item-text-wrapper text-left"
                      },
                      [
                        _c("span", { staticClass: "parking-card-item-title" }, [
                          _vm._v(_vm._s(item.title))
                        ]),
                        _c("br"),
                        _c(
                          "span",
                          { staticClass: "parking-card-item-number" },
                          [_vm._v(_vm._s(_vm._f("text")(item.count)))]
                        ),
                        _c("span", { staticClass: "parking-card-item-unit" }, [
                          _vm._v(_vm._s(item.unit))
                        ])
                      ]
                    )
                  ]
                )
              }),
              1
            )
          ],
          1
        ),
        _c(
          "a-row",
          { attrs: { gutter: 16 } },
          [
            _c(
              "a-col",
              { attrs: { span: 12 } },
              [
                _c(
                  "jtl-card",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.facilityStatusDataLoading,
                        expression: "facilityStatusDataLoading"
                      }
                    ],
                    attrs: { title: "设备状态统计" }
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "jtl-link",
                        attrs: {
                          slot: "extra",
                          to:
                            "/dashboard/parking-chart/elevator-facility-status"
                        },
                        slot: "extra"
                      },
                      [_vm._v("查看")]
                    ),
                    _c("jtl-ring", {
                      attrs: {
                        data: _vm.facilityStatusData,
                        settings: _vm.ChartRingSettings,
                        extend: _vm.ChartRingExtend,
                        "legend-visible": false
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "a-col",
              { attrs: { span: 12 } },
              [
                _c(
                  "jtl-card",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.alarmStatusCountDataLoading,
                        expression: "alarmStatusCountDataLoading"
                      }
                    ],
                    attrs: { title: "告警状态统计" }
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "jtl-link",
                        attrs: {
                          slot: "extra",
                          to: "/dashboard/elevator-event"
                        },
                        slot: "extra"
                      },
                      [_vm._v("查看")]
                    ),
                    _c("jtl-ring", {
                      attrs: {
                        data: _vm.alarmStatusCountData,
                        settings: _vm.ChartRingSettings,
                        extend: _vm.ChartRingExtend,
                        "legend-visible": false
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("br"),
        _c(
          "a-row",
          { attrs: { gutter: 16 } },
          [
            _c(
              "a-col",
              { attrs: { span: 12 } },
              [
                _c(
                  "jtl-card",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.facilityLocationDataLoading,
                        expression: "facilityLocationDataLoading"
                      }
                    ],
                    attrs: { title: "设备空间分布统计" }
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "jtl-link",
                        attrs: {
                          slot: "extra",
                          to: "/dashboard/elevator-facility-list"
                        },
                        slot: "extra"
                      },
                      [_vm._v("查看")]
                    ),
                    _c("jtl-histogram", {
                      attrs: {
                        data: _vm.facilityLocationData,
                        "legend-visible": false
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "a-col",
              { attrs: { span: 12 } },
              [
                _c(
                  "jtl-card",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.last7DayAlarmDataLoading,
                        expression: "last7DayAlarmDataLoading"
                      }
                    ],
                    attrs: { title: "近7天告警趋势" }
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "jtl-link",
                        attrs: {
                          slot: "extra",
                          to: "/dashboard/parking-chart/elevator-alarm-trend"
                        },
                        slot: "extra"
                      },
                      [_vm._v("查看")]
                    ),
                    _c("jtl-line", {
                      attrs: {
                        data: _vm.last7DayAlarmData,
                        "legend-visible": false
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }