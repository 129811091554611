



























































import { Component, Mixins } from 'vue-property-decorator';
import { ReportChartRingComponent, ReportChartHistogramComponent } from '@/mixins/report-chart-base';
import ElevatorIndexService from './service/elevator-index';
import { FireAlarmTrendQueryModel } from './model/fire-alarm-trend-model';
import { ElevatorAlarmTrendQueryModel } from './model/elevator-alarm-trend';

@Component
export default class ElevatorIndexComponent extends Mixins(ReportChartRingComponent, ReportChartHistogramComponent) {
    headerCardList: Array<any> = [
        { title: '设备总数', count: null, unit: '台', loading: true, icon: '/img/device.png' },
        { title: '正常总数', count: null, unit: '台', loading: true, icon: '/img/device-online.png' },
        { title: '异常设备', count: null, unit: '台', loading: true, icon: '/img/fault.png' },
        { title: '正在告警', count: null, unit: '条', loading: true, icon: '/img/alarm.png' },
        { title: '未处理告警', count: null, unit: '条', loading: true, icon: '/img/alarm-unhandle.png' }
    ];
    facilityLocationData: any = {};
    facilityLocationDataLoading: boolean = true;

    alarmStatusCountData: any = null;
    alarmStatusCountDataLoading: boolean = true;

    last7DayAlarmData: any = {};
    last7DayAlarmDataLoading: boolean = true;

    facilityStatusData: any = null;
    facilityStatusDataLoading: boolean = true;

    created() {
        this.initData();
    }

    initData() {
        ElevatorIndexService.getFacilityCount().then(res => {
            this.headerCardList[0].count = res.totalCount;
            this.headerCardList[1].count = res.normalCount;
            this.headerCardList[2].count = res.faultCount;
        }).finally(() => {
            this.headerCardList[0].loading = false;
            this.headerCardList[1].loading = false;
            this.headerCardList[2].loading = false;
        });

        ElevatorIndexService.getAlarmCount().then(res => {
            this.headerCardList[3].count = res.activeCount;
            // this.headerCardList[4].count = res.historyCount;
        }).finally(() => {
            this.headerCardList[3].loading = false;
            // this.headerCardList[4].loading = false;
        });

        ElevatorIndexService.getFacilityStatusCount().then(res => {
            this.facilityStatusData = res;
        }).finally(() => this.facilityStatusDataLoading = false);

        ElevatorIndexService.getAlarmStatusCount().then(res => {
            this.alarmStatusCountData = res;
            this.headerCardList[4].count = _.get(res, 'rows[1].value');
        }).finally(() => {
            this.alarmStatusCountDataLoading = false;
            this.headerCardList[4].loading = false;
        });

        ElevatorIndexService.getFacilityLocationCount().then(res => {
            this.facilityLocationData = res;
        }).finally(() => this.facilityLocationDataLoading = false);
        const model = new ElevatorAlarmTrendQueryModel();
        ElevatorIndexService.getLast7DayAlarmCount(model).then(res => {
            this.last7DayAlarmData = res;
        }).finally(() => this.last7DayAlarmDataLoading = false);
    }
}
